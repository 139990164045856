import { FC, useCallback, useEffect, useState } from 'react'
import { Admin as ReactAdmin, CustomRoutes, Login, Resource } from 'react-admin'
import { Route } from 'react-router-dom'

import {
  BookOpenIcon,
  BriefcaseIcon,
  BuildingStorefrontIcon,
  CloudArrowUpIcon,
  GlobeAltIcon,
  QueueListIcon,
  TagIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'

import { FlagsProvider } from 'flagged'
import { useTranslation } from 'react-i18next'
import { QueryClient } from 'react-query'
import Dashboard from './Dashboard/Dashboard'

import OrganisationList from './Organisations/OrganisationList'
import OrganisationShow from './Organisations/OrganisationShow'
import OrganisationCreate from './Organisations/OrganisationCreate'
import OrganisationEdit from './Organisations/OrganisationEdit'

import ImportLogList from './ImportLogs/ImportLogList'
import ImportLogShow from './ImportLogs/ImportLogShow'

import TransactionList from './Transactions/TransactionList'
import TransactionEdit from './Transactions/TransactionEdit'

import VendorList from './Vendors/VendorList'
import VendorEdit from './Vendors/VendorEdit'
import VendorCreate from './Vendors/VendorCreate'
import VendorShow from './Vendors/VendorShow'

import UserList from './Users/UserList'
import UserEdit from './Users/UserEdit'
import UserCreate from './Users/UserCreate'

import dataProvider from './dataProvider'
import authProvider from './authProvider'

import spendQubeTheme from './theme'

import SQLayout from './components/SQLayout'

import { LoginForm } from './components/LoginForm'

import TagCategoryList from './Tags/TagCategoryList'
import TagList from './Tags/TagList'
import TagCreate from './Tags/TagCreate'
import TagEdit from './Tags/TagEdit'

import userPermissions from './Users/userPermissions'
import { ProfilePage } from './Profiles/ProfilePage'
import EventList from './Event/EventList'
import GroupList from './Groups/GroupList'
import GroupCreate from './Groups/GroupCreate'
import GroupEdit from './Groups/GroupEdit'
import GroupShow from './Groups/GroupShow'
import ImportLogCreate from './ImportLogs/ImportLogCreate'
import ImportLogEdit from './ImportLogs/ImportLogEdit'
import { CLAIM, LabelProvider, SPEND } from './labelProvider'
import externalHttpClient from './externalHttpClient'
import TagCategoryCreate from './Tags/TagCategoryCreate'
import TagCategoryEdit from './Tags/TagCategoryEdit'
import TagCategoryShow from './Tags/TagCategoryShow'
import { DateRangeFilterProvider } from './contexts/DateRangeFilterContext'

const apiUrl = process.env.REACT_APP_ADMIN_API

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
})

const LoginPage = () => {
  return (
    <Login className="sq-login">
      <LoginForm />
    </Login>
  )
}

export const Admin: FC = () => {
  const { t } = useTranslation()
  const [features, setFeatures] = useState({})
  const [noun, setNoun] = useState(SPEND)
  const [suffix, setSuffix] = useState('')

  const fetchFeatures = useCallback(async () => {
    try {
      const result = await fetch(`${apiUrl}/features`)
      const response = await result.json()
      setFeatures(response)
    } catch (e) {
      console.error(e)
    }
  }, [])

  const fetchMe = useCallback(async () => {
    try {
      const result = await externalHttpClient(`${apiUrl}/users/me`)
      if (result.status === 200) {
        setNoun(result.json.organisation?.noun)
        if (result.json.organisation?.noun === CLAIM) {
          setSuffix('_alt')
        }
        const details = {
          fullName: result.json.name,
          ...result.json,
        }
        localStorage.setItem('user', JSON.stringify(details))
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    fetchFeatures()
    fetchMe()
  }, [fetchFeatures, fetchMe])

  return (
    <FlagsProvider features={features}>
      <DateRangeFilterProvider>
        <LabelProvider>
          <ReactAdmin
            queryClient={queryClient}
            dataProvider={dataProvider}
            authProvider={authProvider}
            dashboard={Dashboard}
            disableTelemetry
            title="Procurato - Spendtool"
            basename="/admin"
            requireAuth
            layout={SQLayout}
            theme={spendQubeTheme}
            loginPage={LoginPage}
          >
            {(permissions) => {
              return (
                <>
                  <CustomRoutes>
                    <Route path="/profile" element={<ProfilePage />} />
                  </CustomRoutes>

                  {permissions & userPermissions.VIEW_USERS ? (
                    <Resource
                      name="users"
                      list={UserList}
                      icon={UserGroupIcon}
                      create={
                        permissions & userPermissions.CREATE_USERS
                          ? UserCreate
                          : undefined
                      }
                      edit={
                        permissions & userPermissions.EDIT_USERS
                          ? UserEdit
                          : undefined
                      }
                    />
                  ) : (
                    <Resource name="users" />
                  )}

                  {/* eslint-disable-next-line no-nested-ternary */}
                  {permissions & userPermissions.EDIT_TRANSACTIONS ? (
                    <>
                      <Resource
                        name="transactions"
                        options={{
                          label:
                            noun === SPEND ? t(`transactions`) : t('claims'),
                        }}
                        list={TransactionList}
                        icon={QueueListIcon}
                        edit={TransactionEdit}
                      />
                      <Resource
                        name="import_logs"
                        options={{ label: t('import_logs') }}
                        create={ImportLogCreate}
                        list={ImportLogList}
                        edit={ImportLogEdit}
                        show={ImportLogShow}
                        icon={CloudArrowUpIcon}
                      />
                      <Resource
                        name="events"
                        options={{ label: t('events') }}
                        list={EventList}
                        icon={BookOpenIcon}
                      />
                    </>
                  ) : permissions & userPermissions.VIEW_TRANSACTIONS ? (
                    <>
                      <Resource
                        name="transactions"
                        options={{
                          label:
                            noun === SPEND ? t(`transactions`) : t('claims'),
                        }}
                        list={TransactionList}
                        icon={QueueListIcon}
                      />
                      <Resource
                        name="events"
                        options={{ label: t('events') }}
                        list={EventList}
                        icon={BookOpenIcon}
                      />
                    </>
                  ) : (
                    <>
                      <Resource name="transactions" />
                      <Resource name="import_logs" />
                    </>
                  )}

                  {permissions & userPermissions.VIEW_TAG_CATS ? (
                    <Resource
                      name="tag_categories"
                      options={{ label: t('tags') }}
                      list={TagCategoryList}
                      edit={TagCategoryEdit}
                      create={TagCategoryCreate}
                      show={TagCategoryShow}
                      icon={TagIcon}
                    />
                  ) : (
                    <Resource name="tag_categories" />
                  )}

                  <Resource
                    name="tags"
                    list={TagList}
                    edit={TagEdit}
                    create={TagCreate}
                  />

                  {permissions & userPermissions.VIEW_TRANSACTIONS ? (
                    <Resource
                      name="vendors"
                      options={{ label: t(`vendors${suffix}`) }}
                      list={VendorList}
                      edit={VendorEdit}
                      create={VendorCreate}
                      show={VendorShow}
                      icon={BuildingStorefrontIcon}
                    />
                  ) : (
                    <Resource name="vendors" />
                  )}
                  {permissions & userPermissions.VIEW_TRANSACTIONS ? (
                    <Resource
                      name="groups"
                      options={{ label: t('groups') }}
                      list={GroupList}
                      edit={GroupEdit}
                      create={GroupCreate}
                      show={GroupShow}
                      icon={GlobeAltIcon}
                    />
                  ) : (
                    <Resource name="groups" />
                  )}

                  {permissions & userPermissions.SUPER_ADMIN ? (
                    <Resource
                      name="organisations"
                      options={{ label: t('organisations') }}
                      list={OrganisationList}
                      show={OrganisationShow}
                      create={OrganisationCreate}
                      edit={OrganisationEdit}
                      icon={BriefcaseIcon}
                    />
                  ) : null}
                </>
              )
            }}
          </ReactAdmin>
        </LabelProvider>
      </DateRangeFilterProvider>
    </FlagsProvider>
  )
}

export default Admin
